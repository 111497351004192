import { COMPANY_STATUS, COMPAY_SECTOR, SPV } from '@constants/enums';
import { cn } from '@utils/utils';

interface MoicColumnProps {
  investmentAmount: number;
  latestSharePrice: number;
  totalShares?: number;
  sector: string[];
  status?: COMPANY_STATUS;
  lastValuation?: number;
  companyID?: string;
  spv?: SPV;
  name: string;
}

const MoicColumn = ({
  investmentAmount,
  latestSharePrice,
  totalShares,
  sector,
  status,
  lastValuation,
  companyID,
  spv,
}: MoicColumnProps) => {
  if (sector && sector.includes(COMPAY_SECTOR.VC)) {
    return <span className={cn('text-primary-black')}>N/A</span>;
  }

  if (status === COMPANY_STATUS.CLOSED || lastValuation === 0) {
    return <span className={cn('text-primary-black')}>0</span>;
  }

  if (!companyID || !totalShares) {
    if (spv === SPV.YES) {
      return <span className={cn('text-primary-black')}>-</span>;
    } else {
      return <span className={cn('text-primary-black')}>1.0</span>;
    }
  }

  const moic = (totalShares * latestSharePrice) / investmentAmount;
  return <span className={cn('text-primary-black')}>{moic && moic.toFixed(1)}</span>;
};

export default MoicColumn;
