import { COMPANY_STATUS, COMPAY_SECTOR, SPV } from '@constants/enums';
import { formatCurrencyWithoutFractionDigits } from '@utils/helpers';
import { cn } from '@utils/utils';

interface CurrentValueColumnProps {
  totalShares?: number;
  latestSharePrice: number;
  investmentAmount: number;
  sector: string[];
  status?: COMPANY_STATUS;
  lastValuation?: number;
  companyID?: string;
  spv?: SPV;
  name: string;
}

const CurrentValueColumn = ({
  totalShares,
  latestSharePrice,
  investmentAmount,
  sector,
  status,
  lastValuation,
  spv,
  companyID,
}: CurrentValueColumnProps) => {
  if (sector && sector.includes(COMPAY_SECTOR.VC)) {
    return <span className={cn('text-primary-black')}>Not Applicable</span>;
  }

  if (status === COMPANY_STATUS.CLOSED || lastValuation === 0) {
    return <span className={cn('text-primary-black')}>$0</span>;
  }

  if (!companyID || !totalShares) {
    if (spv === SPV.YES) {
      return <span className={cn('text-primary-black')}>Not Yet Available</span>;
    } else {
      return (
        <span className={cn('text-primary-black')}>
          {formatCurrencyWithoutFractionDigits(investmentAmount)}
        </span>
      );
    }
  }

  const currentValue = totalShares * latestSharePrice;
  return (
    <span className={cn('text-primary-black')}>
      {formatCurrencyWithoutFractionDigits(currentValue)}
    </span>
  );
};

export default CurrentValueColumn;
